<template>
  <div class="create-speaker-table">
    <div class="title">活動講者</div>

    <el-table
      :data="speakers"
      @select="handleSelectionChange"
      ref="speakerTableRef"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column label="ID" prop="speaker_id"></el-table-column>
      <el-table-column label="中文名稱" prop="name_zh"></el-table-column>
      <el-table-column label="英文名稱" prop="name_en"></el-table-column>
    </el-table>

    <div class="pagination-block flex-end">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="setup.page + 1"
        :page-size="setup.limit"
        layout="prev, pager, next"
        :total="total"
        background
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { getSpeakersPagination } from "@/api/activity-speaker";

export default {
  name: "CreateSpeakerTable",
  props: {
    currentSpeakers: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      speakers: [],
      total: 0,
      setup: {
        limit: 10,
        page: 0,
      },
    };
  },
  created() {
    this.handleGetSpeakers();
  },
  methods: {
    async handleGetSpeakers() {
      const { results, total } = await getSpeakersPagination(this.setup);

      this.total = total;
      this.speakers = results;

      this.$nextTick(() => {
        if (this.currentSpeakers[this.setup.page]) {
          this.speakers.forEach(({ speaker_id }, index) => {
            if (this.currentSpeakers[this.setup.page].includes(speaker_id)) {
              console.log("in");
              this.$refs.speakerTableRef.toggleRowSelection(
                this.speakers[index],
                true
              );
            }
          });
        }
      });
    },
    handleCurrentChange(page) {
      this.setup.page = page - 1;

      this.handleGetSpeakers();
    },
    handleSelectionChange(row) {
      this.$emit("update:speakers", {
        page: this.setup.page,
        speakers: row.map(({ speaker_id }) => speaker_id),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.create-speaker-table {
  .title {
    font-size: 14px;
    color: #606266;
    margin-bottom: 12px;
  }

  .pagination-block {
    margin-top: 24px;
  }
}
</style>
